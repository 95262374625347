import Layout from "../components/layout"
import React from "react"
import SEO from "../components/seo"
import MailSVG from "../images/mail.svg"
import DownloadSVG from "../images/download.svg"
import resume from "../files/Bhowmik_Susmita_Resume.pdf"

export default () => (
  <Layout>
    <SEO title="Susmita Bhowmik" />
    <div class="main-content">
      <div class="wrapper">
        <h1>Experience</h1>
        <p>
          Sparkeats is a restaurant review site where Sparkboxers review
          restaurants. During my time at Sparkbox, I worked as part of a team
          utilizing agile methodology to implement a redesign for the site on
          the frontend. I also worked on the backend to refactor legacy code,
          write tests, and fix bugs.
        </p>
        <p class="project__tech-stack">
          Node.js, Sails.js, SQL, Ramda.js, Mocha, Chai, Sinon, HTML, SCSS, BEM
        </p>
        <a class="button" href="https://eats.seesparkbox.com/refresh">
          Redesigned Sparkeats Site
        </a>
      </div>

      <div class="wrapper">
        <h1>Core competencies</h1>
        <p>
          Whatever is an app for indecisive people. I created it as my capstone
          project for Actualize. It helps users decide what to do or where to
          eat by choosing a random event or a restaurant based on the given
          address, radius, and date/time restrictions. Users are also able to
          filter their search results by price, and view directions to their
          destination.
        </p>
        <p class="project__tech-stack">
          Vue.js, CSS, HTML, MapBox API, JavaScript, Ruby on Rails, Google Maps
          API, Ticketmaster API
        </p>
        <a class="button" href="https://whatever-app.herokuapp.com/">
          Whatever Site
        </a>
      </div>

      <div class="wrapper">
        <h1>Education</h1>
        <p>
          Whatever is an app for indecisive people. I created it as my capstone
          project for Actualize. It helps users decide what to do or where to
          eat by choosing a random event or a restaurant based on the given
          address, radius, and date/time restrictions. Users are also able to
          filter their search results by price, and view directions to their
          destination.
        </p>
        <p class="project__tech-stack">
          Vue.js, CSS, HTML, MapBox API, JavaScript, Ruby on Rails, Google Maps
          API, Ticketmaster API
        </p>
        <a class="button" href="https://whatever-app.herokuapp.com/">
          Whatever Site
        </a>
      </div>

      <div class="wrapper">
        <h1 class="homepage__header">Let's connect!</h1>
        <div>
          <a class="homepage__link" href="mailto:susmita.bhowmik516@gmail.com">
            <img alt="mail to" class=" homepage__image" src={MailSVG}></img>
            susmita.bhowmik516@gmail.com{" "}
          </a>
        </div>

        <div>
          <a
            href={resume}
            class="homepage__link"
            target="_blank"
            rel="noopener noreferrer"
            download="Bhowmik_Susmita_Resume"
          >
            <img
              alt="download resume"
              class=" homepage__image"
              src={DownloadSVG}
            ></img>
            Download PDF Resume
          </a>
        </div>
      </div>
    </div>
  </Layout>
)
